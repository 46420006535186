import { UTooltip } from "#components";

export const formatNumber = (number: number, precision: number = 2) =>
    Intl.NumberFormat("en-US", {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
    }).format(number);

export const formatDate = (date: string, opts = {}) => {
    return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        ...opts
    });
};

export function getTeamAmount (mode: string) {
    if (mode.match(/[1-9]p/)) {
        return 1;
    }

    if (mode.match(/[1-9]vs[1-9]vs[1-9]/)) {
        return 3;
    }

    if (mode.match(/[1-9]vs[1-9]/)) {
        return 2;
    }

    if (mode.match(/[1-9]-way/)) {
        return parseInt(mode.replace("-way", ""));
    }

    return 1;
}

export function getTeamSize (mode: string) {
    if (mode.match(/[1-9]p/)) {
        return parseInt(mode.replace("p", ""));
    }

    if (mode.match(/[1-9]vs(.+)/)) {
        const parts = mode.split("vs");
        return parseInt(parts[0]);
    }

    if (mode.match(/[1-9]vs[1-9]vs[1-9]/)) {
        return 3;
    }

    if (mode.match(/[1-9]-way/)) {
        return 1;
    }

    return 1;
}

export function getPlayersByMode (mode: string) {
    const amount = getTeamAmount(mode);
    const size = getTeamSize(mode);

    return amount * size;
}

export function toFixedIfNecessary (value: number, dp: number) {
    return +value.toFixed(dp);
}

export const COINFLIP_SPRITES = {
    flipBlack: {
        src: "/sprites/coinflip/flip-black.png",
        rows: 10,
        isFlip: true
    },
    flipBlue: {
        src: "/sprites/coinflip/flip-blue.png",
        rows: 10,
        isFlip: true
    },
    noFlipBlack: {
        src: "/sprites/coinflip/noflip-black.png",
        rows: 9,
        isFlip: false
    },
    noFlipBlue: {
        src: "/sprites/coinflip/noflip-blue.png",
        rows: 9,
        isFlip: false
    }
};

export const parseEmoji = (str: string) => {
    const emojis = useEmojiStore();

    str = str.replace(/:/g, "");

    const emoji = emojis.find(str);

    if (!emoji) {
        return h("span", `:${str}:`);
    }

    return h(
        UTooltip,
        {
            text: emoji.name,
            popper: { placement: "top" },
            class: "relative inline-block"
        },
        () =>
            h("img", {
                src: emoji.path,
                alt: emoji.name,
                loading: "lazy",
                class: "h-6 w-6 translate-y-px"
            })
    );
};

export const parseChatMessage = (content: string = "") => {
    if (content && content.includes(":")) {
        const parts = content.split(/(:[^:]+:)/g);
        const parsedParts = parts.map((part, index) => {
            if (index % 2 === 0) {
                return part;
            }

            return h(parseEmoji(part));
        });

        let nodes: any = [];

        parsedParts.forEach(part => {
            if (typeof part === "string") {
                nodes.push(h("span", {
                    class: "align-bottom"
                }, part));
            } else {
                nodes.push(part);
            }
        });

        if (nodes.length > 1) {
            nodes = [h("p", nodes)];
        }

        return nodes[0];
    }

    return h("span", content);
};

export const clamp = (value: number, min: number, max: number) => {
    return Math.min(Math.max(value, min), max);
};

export const TX_STATUS: any = {
    pending: {
        label: "Pending",
        color: "#FFBF85"
    },
    completed: {
        label: "Completed",
        color: "#69E39A"
    },
    failed: {
        label: "Failed",
        color: "#FF7474"
    },
    canceled: {
        label: "Canceled",
        color: "#FF7474"
    }
};

export const linkToTransaction = (data: TransactionInteface) => {
    if (!data.extra_data?.currency) {
        return "#";
    }
    return `https://blockchain.com/explorer/transactions/${data.extra_data.currency}/${data.extra_data.transactionId}`;
};

export const copyToClipboard = (
    text: string,
    str: string = "Copied to clipboard"
) => {
    const toast = useToast();

    navigator.clipboard.writeText(text);

    toast.add({
        color: "green",
        title: str
    });
};

export const socials = [
    {
        name: "Facebook",
        to: "facebook",
        icon: "i-ri-facebook-circle-fill"
    },
    {
        name: "Instagram",
        to: "instagram",
        icon: "i-ri-instagram-fill"
    },
    { name: "Discord", to: "discord", icon: "i-ri-discord-fill" },
    { name: "X", to: "x", icon: "i-ri-twitter-x-fill" },
    { name: "Youtube", to: "youtube", icon: "i-ri-youtube-fill" },
    { name: "Tiktok", to: "tiktok", icon: "i-ri-tiktok-fill" },
    { name: "Twitch", to: "twitch", icon: "i-ri-twitch-fill" },
    { name: "Kick", to: "kick", icon: "i-ri-kick-fill" },
    { name: "Steam", to: "steam", icon: "i-ri-steam-fill" }
];

export const zenMethods = [
    {
        name: "Mastercard",
        image: "/images/zen/mastercard.svg",
        value: "mastercard"
    },
    {
        name: "Visa",
        image: "/images/zen/visa.svg",
        value: "visa"
    },
    {
        name: "Apple Pay",
        image: "/images/zen/apple_pay.svg",
        value: "apple_pay"
    },
    {
        name: "Google Pay",
        image: "/images/zen/google_pay.svg",
        value: "google_pay"
    },
    {
        name: "Zen",
        image: "/images/zen/zen.svg",
        value: "zen"
    },
    {
        name: "Blik",
        image: "/images/zen/blik.svg",
        value: "blik"
    },
    {
        name: "Bank Transfer",
        image: "/images/zen/bank_transfer.svg",
        value: "bank_transfer"
    },
    {
        name: "PaySafeCard",
        image: "/images/zen/paysafecard.svg",
        value: "paysafecard"
    },
    {
        name: "PaySafeCash",
        image: "/images/zen/paysafecash.svg",
        value: "paysafecash"
    },
    {
        name: "UnionPay",
        image: "/images/zen/union_pay.svg",
        value: "union_pay"
    },
    {
        name: "Neosurf",
        image: "/images/zen/neosurf.svg",
        value: "neosurf"
    },
    {
        name: "WeChat Pay",
        image: "/images/zen/wechat_pay.svg",
        value: "wechat_pay"
    },
    {
        name: "iDeal",
        image: "/images/zen/ideal.svg",
        value: "ideal"
    },
    {
        name: "Bancontact",
        image: "/images/zen/bancontact.svg",
        value: "bancontact"
    },
    {
        name: "Neteller",
        image: "/images/zen/neteller.svg",
        value: "neteller"
    },
    {
        name: "Sofort",
        image: "/images/zen/sofort.svg",
        value: "sofort"
    },
    {
        name: "Skrill",
        image: "/images/zen/skrill.svg",
        value: "skrill"
    },
    {
        name: "Twisto",
        image: "/images/zen/twisto.svg",
        value: "twisto"
    },
    {
        name: "Digital Currencies",
        image: "/images/zen/digital_currency.svg",
        value: "digital_currencies"
    }
    // {
    //     name: "Cards",
    //     image: "/images/deposit/visa.png",
    //     value: "visa"
    // },
    // {
    //     name: "G Pay",
    //     image: "/images/deposit/gpay.png",
    //     value: "gpay"
    // },
    // {
    //     name: "Apple Pay",
    //     image: "/images/deposit/apay.png",
    //     value: "apay"
    // },
    // {
    //     name: "Paysafecard",
    //     image: "/images/deposit/paysafecard.png",
    //     value: "paysafecard"
    // },
    // {
    //     name: "PaySafeCash",
    //     image: "/images/deposit/paysafecash.png",
    //     value: "paysafecash"
    // },
    // {
    //     name: "Neosurf",
    //     image: "/images/deposit/neosurf.png",
    //     value: "neosurf"
    // },
    // {
    //     name: "WeChat Pay",
    //     image: "/images/deposit/wechat.png",
    //     value: "wechat"
    // },
    // {
    //     name: "Neteller",
    //     image: "/images/deposit/neteller.png",
    //     value: "neteller"
    // },
    // {
    //     name: "Skrill",
    //     image: "/images/deposit/skrill.png",
    //     value: "Skrill"
    // }
];
