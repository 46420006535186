import { Crisp } from "crisp-sdk-web";

export default defineNuxtPlugin({
    parallel: true,
    setup: (nuxtApp) => {
        if (!nuxtApp.$config.public.crispWebsiteId) {
            return;
        }

        Crisp.configure(nuxtApp.$config.public.crispWebsiteId);
    }
});
