import {
    ModalsGamesWinModal,
    ModalsLeaderboardWin,
    ModalsPopupTradeSent
} from "#components";
import { defineStore } from "pinia";
import { io, Socket } from "socket.io-client";

export const useSocketStore = (namespace: string = "") => {
    return defineStore(`socket-${namespace}`, () => {
        const socket = ref<Socket | null>(null);
        const connected = ref<boolean>(false);
        const auth = useAuthStore();
        const toast = useToast();
        const customToast = useToast();
        const route = useRoute();
        const config = useRuntimeConfig();
        const configData = ref<AppConfig>();
        const modal = useModal();
        const mightBeErroring = ref<boolean>(false); // indicate if it might have failed
        const userCount = ref<number>(0);
        const handleForRouteCallback = ref<Function | null>(null);

        const noConnectionToastIds = ref<any[]>([]);

        const uniqueId = useCookie("socket-id");

        const load = async () => {
            if (socket.value && socket.value.connected) {
                return;
            }

            if (!uniqueId.value) {
                uniqueId.value =
                    Math.random().toString(36).substring(2, 15) +
                    Math.random().toString(36).substring(2, 15);
            }

            // use websocket as long as we don't use sticky sessions yet
            socket.value = io(config.public.apiUrl + `${namespace}`, {
                transports: ["websocket"],
                auth: {
                    token: auth.token
                },
                query: {
                    "x-socket-id": uniqueId.value
                }
            });

            socket.value.on("connect", async () => {
                connected.value = true;
                mightBeErroring.value = true;

                if (handleForRouteCallback.value) {
                    handleForRouteCallback.value();
                }

                if (noConnectionToastIds.value.length) {
                    toast.add({
                        title: "Connected",
                        description: "You are now connected to the server.",
                        color: "green"
                    });

                    noConnectionToastIds.value.forEach(toast.remove);
                    noConnectionToastIds.value = [];

                    // if (!import.meta.dev) {
                    //     window.location.reload();
                    // }
                }

                await auth.load();

                if (auth.user) {
                    socket.value!.emit("leaderboard:check");
                }
            });

            socket.value.on("disconnect", () => {
                connected.value = false;

                const errorToast = toast.add({
                    title: "Connection lost",
                    description: "Please wait while we try to reconnect.",
                    color: "red",
                    timeout: 0,
                    actions: [
                        {
                            label: "Refresh",
                            click: () => window.location.reload()
                        }
                    ]
                });

                noConnectionToastIds.value.push(errorToast.id);
            });

            socket.value.on("error", error => {
                if (import.meta.dev) {
                    console.error(error);
                }

                if (error.message) {
                    toast.add({
                        title: error.message,
                        color: "red"
                    });
                }
            });

            socket.value.on("connect_error", () => {
                mightBeErroring.value = true;

                const errorToast = toast.add({
                    title: "Connection error",
                    description:
                        "Couldn't connect to the server, trying again...",
                    color: "red"
                });

                noConnectionToastIds.value.push(errorToast.id);
            });

            // todo: better toast handling?
            socket.value.on("notification", notification =>
                customToast.add(notification)
            );

            socket.value.on(
                "leaderboard:check",
                ({ reward }: { reward: LeaderboardPeriodRewardInterface }) => {
                    if (!reward) { return; }

                    modal.open(ModalsLeaderboardWin, { reward });
                }
            );

            // todo: make this generic
            socket.value.on(
                "user:message",
                async ({ action, data }: { action: string; data?: any }) => {
                    switch (action) {
                    case "tradeOffer":
                        await modal.close();
                        setTimeout(
                            () => modal.open(ModalsPopupTradeSent, data),
                            500
                        );
                        break;

                    case "coins":
                        if (auth.user) {
                            auth.user.coins = data.coins;
                            auth.user.wager_rest = data.wagerRest;
                            auth.user.vault_coins = data.vaultCoins;
                        }
                        break;

                    case "coinflip:win":
                        // own page will handle it at the right timing
                        if (route.path.includes("coinflip")) {
                            return;
                        }

                        modal.close();

                        setTimeout(() => {
                            modal.open(ModalsGamesWinModal, {
                                type: "coinflip",
                                game: data.game
                            });
                        }, 1000);
                        break;

                    case "levelUp":
                        await auth.fetchUser();
                        break;

                    case "toast_success":
                        toast.add({
                            title: data.title,
                            description: data.description,
                            color: "green"
                        });
                        break;

                    case "rakeback:updated":
                        if (auth.user) {
                            for (const key in data) {
                                if (auth.user[key as keyof UserInterface]) {
                                    (auth.user as any)[key] =
                                            data[key] / 100;
                                }
                            }
                        }
                        break;
                    }
                }
            );

            socket.value.on("app:config", (data: AppConfig) => {
                configData.value = data;
            });

            socket.value.on("chat:count", ({ count }: { count: number }) => {
                userCount.value = count;
            });
        };

        const disconnect = () => socket.value?.disconnect();

        // allows one-time registration listening of any event
        const register = (
            event: string,
            callback: (...args: any[]) => void
        ) => {
            socket.value?.removeAllListeners(event);
            socket.value?.on(event, callback);
        };

        const handleForRoute = (
            callback: () => void,
            execute: boolean = true
        ) => {
            handleForRouteCallback.value = callback;

            if (execute) {
                callback();
            }
        };

        watch(
            () => route.path,
            () => {
                handleForRouteCallback.value = null;
            }
        );

        return {
            load,
            disconnect,
            register,
            handleForRoute,

            socket,
            connected,
            config: configData,
            mightBeErroring,
            userCount
        };
    });
};
