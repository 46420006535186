import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { ModalsGamesWinModal, ModalsLoginNotice } from "#components";

export const useAuthStore = defineStore("auth", () => {
    const config = useRuntimeConfig();
    const route = useRoute();
    const user = ref<UserInterface | null>(null);
    const token = useStorage<string | null>("token", null, localStorage);
    const clientSeed = useStorage<string | null>(
        "clientSeed",
        Math.random().toString(36).slice(2),
        localStorage
    );
    const modal = useModal();

    const load = async () => {
        if (token.value) {
            return fetchUser();
        }
    };

    const login = async () => {
        return fetch(config.public.apiUrl + "steam/redirect", {
            method: "GET"
        })
            .then(res => res.json())
            .then(res => {
                window.location.href = res.url;
            });
    };

    const callback = async (queries: any, manual: boolean = false) => {
        const referral = useCookie("referral");

        if (referral.value) {
            queries.referral = referral.value;
        }

        return fetch(
            config.public.apiUrl +
                (manual ? "steam/manual-login" : "steam/callback") +
                "?" +
                new URLSearchParams(queries).toString(),
            {
                method: "GET"
            }
        )
            .then(res => res.json())
            .then(res => setToken(res.token));
    };

    const setToken = (newToken: string) => {
        token.value = newToken;
        return fetchUser();
    };

    const setClientSeed = (newClientSeed: string) => {
        clientSeed.value = newClientSeed;
    };

    const logout = async () => {
        const { error } = await useApi("auth/logout", {
            method: "DELETE"
        });

        if (error.value) {
            return;
        }

        localStorage.removeItem("token");
        token.value = null;
        user.value = null;

        window.location.href = "/";
    };

    const checkForFullBan = async () => {
        const fullBan = user.value?.activeBans?.find(b => b.type === "full");

        if (!fullBan || route.path === "/block/banned") {
            return;
        }

        // todo: use nuxt
        window.location.href = "/block/banned";
    };

    const checkForUnclaimed = async () => {
        if (route.path.startsWith("/dashboard")) {
            return;
        }

        const { data, error } = await useApi("claims", {
            method: "get"
        });

        if (error.value || !data.value) {
            return;
        }

        if (modal.isOpen.value) {
            modal.close();
            await new Promise(resolve => setTimeout(resolve, 500));
        }

        modal.open(ModalsGamesWinModal, data.value);
    };

    const fetchUser = async (complete: boolean = true) => {
        const { data, error } = await useApi<{ user: UserInterface }>(
            "auth/me",
            {
                headers: {
                    authorization: isLogged.value
                        ? undefined
                        : (("Bearer " + token.value) as any)
                },
                watch: false
            }
        );

        if (error.value) {
            return;
        }

        user.value = data.value?.user ?? null;

        if (complete) {
            await checkForFullBan();
            await checkForUnclaimed();
        }
    };

    const isLogged = computed(() => {
        return user.value && token.value;
    });

    const hasPermission = (permission: string) => {
        if (!user.value) {
            return false;
        }

        const isRoot = user.value.roles.some(role => role.root);

        const isAllowed = user.value.roles.some((role: RoleInterface) =>
            role.permissions?.some(
                p => p.slug === permission && p.allowed === true
            )
        );

        const isDenied = user.value.roles.some((role: RoleInterface) =>
            role.permissions?.some(
                p => p.slug === permission && p.allowed === false
            )
        );

        return isRoot || (isAllowed && !isDenied);
    };

    const openLoginNotice = () => {
        modal.open(ModalsLoginNotice);
    };

    return {
        isLogged,
        user,
        token,
        clientSeed,
        load,
        login,
        callback,
        logout,
        hasPermission,
        setToken,
        setClientSeed,
        checkForUnclaimed,
        fetchUser,
        openLoginNotice
    };
});
