import { defineStore } from "pinia";

interface ConfigInterface {
    wallet_skins_percentage: number;
    wallet_cash_percentage: number;
    wallet_crypto_percentage: number;
    case_battles_borrow_limit: number;
    affiliate_percentage_deposits: number;
    affiliate_percentage_games: number;
    leaderboard_enabled: boolean;
    rewards_enabled: boolean;
}

export const useConfigStore = defineStore("config", () => {
    const configurations = ref<ConfigInterface>();
    const toast = useCustomToast();

    const load = async () => {
        const { data, error } = await useApi<ConfigInterface>("config");

        if (error.value) {
            return;
        }

        configurations.value = data.value!;
    };

    const get = (key: keyof ConfigInterface, defaultValue: any = null) => {
        if (!configurations.value) return defaultValue;
        return configurations.value[key] ?? defaultValue;
    };

    return {
        configurations,

        load,
        get
    };
});
