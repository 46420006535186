export const soundsList = {
    click: "/sounds/click.wav",

    select_skin: "/sounds/select_skin.wav",

    upgrader_win: "/sounds/upgrader/win.wav",
    upgrader_lose: "/sounds/upgrader/lose.wav",

    tower_win: "/sounds/tower/win.wav",
    tower_lose: "/sounds/tower/lose.wav",

    mines_win: "/sounds/mines/win.wav",
    mines_lose: "/sounds/mines/lose.wav",

    coinflip_flip: "/sounds/coinflip/flip_v2.wav",
    coinflip_noflip: "/sounds/coinflip/no_flip_v2.wav",

    wheel_spin: "/sounds/wheel/v2_a.wav",

    cursor_hover_a: "/sounds/cursor/hover_a.wav",
    cursor_hover_b: "/sounds/cursor/hover_b.wav",
    cursor_hover_c: "/sounds/cursor/hover_c.wav",

    // roll: "/sounds/cases/roll.wav",
    case_roll_single: "/sounds/cases/roll.wav",

    // open: "/sounds/cases/open.wav",

    slot_win_1: "/sounds/slot_win_1.wav",
    slot_win_2: "/sounds/slot_win_2.wav",
    slot_win_3: "/sounds/slot_win_3.wav",

    rust_zipline: "/sounds/rust/zipline-mount-01.wav",
    rust_skull_torch: "/sounds/rust/skull-torch-strike-lit-01.wav",

    battles_beep: "/sounds/battles/beep.wav",
    battles_lock: "/sounds/battles/lock.mp3",

    notification_success: "/sounds/test/notification_success.wav",

    notification_error: "/sounds/test/notification_error.wav",

    cash_out: "/sounds/cash_out.wav",

    bet: "/sounds/test/bet.wav",

    button_primary: "/sounds/test/primary_button.wav",
    button_secondary: "/sounds/test/secondary_button.wav",
    button_info: "/sounds/test/info_button.wav",

    money_going_up: "/sounds/money_going_up.wav"
};

export type SoundsList = keyof typeof soundsList;

export default defineNuxtPlugin(() => {
    const audio = useAudioStore();

    for (const [name, path] of Object.entries(soundsList)) {
        audio.register(name as SoundsList, path);
    }

    audio.load();
});
